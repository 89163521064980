import { Chip } from '@vault/Chip';
import { Input } from '@vault/Input';
import {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './styles.module.scss';
import { animate, AnimatePresence, motion, useMotionValue } from 'motion/react';
import { easeOutExpo } from '@vault/utilities';
import composeRefs from '@seznam/compose-react-refs';

function AnimateHeight({ children }: { children: React.ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const height = useMotionValue(0);

  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) return;

    height.set(el.clientHeight);
    const observer = new ResizeObserver(() => {
      animate(height, el.clientHeight, { duration: 0.4, ease: easeOutExpo });
    });

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <motion.div className={styles.animateHeightParent} style={{ height }}>
      <div ref={ref} className={styles.animateHeightChild}>
        {children}
      </div>
    </motion.div>
  );
}

export interface ChipInputProps {
  name?: string;
  value: string[];
  onValueChange(value: string[]): void;
  delimiter: string | RegExp;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const ChipInput = forwardRef<HTMLInputElement, ChipInputProps>(
  function ChipInput(
    { name, value, onValueChange, delimiter, placeholder, disabled, onBlur },
    ref
  ) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState('');

    const [highlightedChips, setHighlightedChips] = useState<string[]>([]);
    const highlightChip = useCallback((value: string) => {
      setHighlightedChips((prev) => [...prev, value]);
      setTimeout(() => {
        setHighlightedChips((prev) => prev.filter((v) => v !== value));
      }, 100);
    }, []);

    const chipListRef = useRef<HTMLUListElement>(null);
    const focusChip = useCallback(
      (value: string) => {
        const buttonEl = chipListRef.current?.querySelector(
          `li[data-value="${value}"] button`
        );
        if (buttonEl && buttonEl instanceof HTMLButtonElement) {
          buttonEl.focus();
        } else {
          inputRef.current?.focus();
        }
      },
      [value]
    );

    const appendValues = useCallback(
      (...newValues: string[]) => {
        const allValues = [...value];
        for (const newValue of newValues.filter(Boolean)) {
          if (allValues.includes(newValue)) {
            highlightChip(newValue);
          } else {
            allValues.push(newValue);
          }
        }

        onValueChange(Array.from(allValues));
      },
      [value, onValueChange, highlightChip]
    );

    const removeValues = useCallback(
      (...values: string[]) => {
        const toRemove = new Set(values);

        const newValues: string[] = [];
        let lastIndex = -1;
        for (let i = 0; i < value.length; i++) {
          if (toRemove.has(value[i])) {
            lastIndex = i;
          } else {
            newValues.push(value[i]);
          }
        }
        onValueChange(newValues);

        const focusIndex = lastIndex % newValues.length;
        const newValue = newValues[focusIndex];
        focusChip(newValue);
      },
      [value, onValueChange, focusChip]
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const values = text.split(delimiter);
        if (values.length > 1) {
          appendValues(...values);
          setInput('');
        } else {
          setInput(text);
        }
      },
      [delimiter, appendValues]
    );

    const handleInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          appendValues(input);
          setInput('');
        }
      },
      [appendValues, input]
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (input) {
          appendValues(input);
          setInput('');
        }

        if (onBlur) {
          onBlur(e);
        }
      },
      [input, appendValues, onBlur]
    );

    return (
      <div className={styles.container}>
        <Input
          ref={composeRefs(ref, inputRef)}
          name={name}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={handleBlur}
          data-1p-ignore
        />

        <AnimateHeight>
          <AnimatePresence initial={false}>
            {value.length > 0 && (
              <motion.ul
                ref={chipListRef}
                className={styles.chipList}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4, ease: easeOutExpo }}
              >
                <AnimatePresence initial={false} mode="popLayout">
                  {value.map((item) => {
                    const isHighlighted = highlightedChips.includes(item);
                    return (
                      <motion.li
                        key={item}
                        layout
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: isHighlighted ? 1.1 : 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 0.4, ease: easeOutExpo }}
                        data-value={item}
                      >
                        <Chip
                          disabled={disabled}
                          onRemove={() => removeValues(item)}
                        >
                          {item}
                        </Chip>
                      </motion.li>
                    );
                  })}
                </AnimatePresence>
              </motion.ul>
            )}
          </AnimatePresence>
        </AnimateHeight>
      </div>
    );
  }
);
