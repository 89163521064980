import {
  Children,
  CSSProperties,
  isValidElement,
  PropsWithChildren,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsomorphicLayoutEffect } from 'motion/react';
import { useFocusVisible } from 'react-aria';
import { Slot } from '@radix-ui/react-slot';

const TEXT_INPUT_TYPES = [
  'text',
  'search',
  'email',
  'password',
  'number',
  'tel',
  'url',
];

export function useFocusRing<Element extends HTMLElement>(): [
  RefObject<Element>,
  CSSProperties,
] {
  const elementRef = useRef<Element>(null);

  const [isTextInput, setIsTextInput] = useState(false);
  useIsomorphicLayoutEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const isTextArea =
      element.tagName === 'TEXTAREA' || element.contentEditable === 'true';
    const isTextInput =
      element.tagName === 'INPUT' &&
      TEXT_INPUT_TYPES.includes(element.getAttribute('type') ?? '');

    setIsTextInput(isTextInput || isTextArea);
  }, []);

  const [focused, setFocused] = useState(false);
  useIsomorphicLayoutEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    elementRef.current?.addEventListener('focus', onFocus);
    elementRef.current?.addEventListener('blur', onBlur);
    return () => {
      elementRef.current?.removeEventListener('focus', onFocus);
      elementRef.current?.removeEventListener('blur', onBlur);
    };
  }, []);

  const { isFocusVisible } = useFocusVisible({ isTextInput });

  const style = useMemo(() => {
    if (!focused || !isFocusVisible) {
      return {};
    }

    return {
      outline: `2px solid var(--focus-ring-color, var(--primary))`,
      outlineOffset: `var(--focus-ring-offset, 0px)`,
    };
  }, [focused, isFocusVisible]);

  return [elementRef, style] as const;
}

export interface FocusRingProps extends PropsWithChildren {}

export function FocusRing({ children }: FocusRingProps) {
  const [elementRef, style] = useFocusRing();

  if (!isValidElement(children) || Children.count(children) !== 1) {
    return children;
  }

  return (
    <Slot ref={elementRef} style={style}>
      {children}
    </Slot>
  );
}
