import { AnimatePresence, motion } from 'motion/react';
import * as Primitive from '@radix-ui/react-select';
import { forwardRef } from 'react';
import css from './styles.module.css';
import connectFieldsStyles from '../ConnectedFields/styles.module.css';
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react';
import SelectOption from './SelectOption';
import { useFormField } from '@common/FormField';
import { SelectLabel } from './SelectLabel';
import { FocusRing } from '@vault/FocusRing';
import { cn } from '@vault/utilities';

export type SelectProps<T extends string> = {
  error?: boolean;
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value?: T;
  label?: string;
  onChange: (value: T) => void;
  inline?: boolean;
};

const Select = forwardRef<HTMLButtonElement, SelectProps<string>>(
  function Select(
    {
      id,
      children,
      placeholder,
      prefix,
      error,
      size = 'md',
      value,
      onChange,
      name,
      label,
      className,
      disabled,
      inline,
    },
    ref
  ) {
    const formField = useFormField();

    const hasError = error || Boolean(formField?.error);

    return (
      <Primitive.Root
        value={value}
        onValueChange={onChange}
        name={name}
        disabled={disabled}
      >
        <FocusRing>
          <Primitive.Trigger
            ref={ref}
            id={id || formField?.id}
            className={cn(
              connectFieldsStyles.connectableField,
              css.select,
              className
            )}
            data-error={hasError}
            data-size={size}
            data-inline={inline}
            aria-label={label}
          >
            {prefix && (
              <span className={css.prefix} aria-hidden>
                {prefix}
              </span>
            )}
            <span className={css.value}>
              <Primitive.Value placeholder={placeholder || 'Select...'} />
            </span>
            <Primitive.Icon className={css.icon}>
              <ChevronsUpDown />
            </Primitive.Icon>
          </Primitive.Trigger>
        </FocusRing>

        <AnimatePresence>
          <Primitive.Portal>
            <Primitive.Content sideOffset={8} position="popper" asChild>
              <motion.div
                data-size={size}
                className={css.menu}
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
              >
                <Primitive.ScrollUpButton className={css.scrollUp}>
                  <ChevronUp />
                </Primitive.ScrollUpButton>
                <Primitive.Viewport>{children}</Primitive.Viewport>
                <Primitive.ScrollDownButton className={css.scrollDown}>
                  <ChevronDown />
                </Primitive.ScrollDownButton>
              </motion.div>
            </Primitive.Content>
          </Primitive.Portal>
        </AnimatePresence>
      </Primitive.Root>
    );
  }
) as <T extends string>(
  props: SelectProps<T> & { ref?: React.Ref<HTMLButtonElement> }
) => JSX.Element;

const SelectNamespace = Object.assign(Select, {
  Option: SelectOption,
  Label: SelectLabel,
  Group: Primitive.Group,
});

export { SelectOption, SelectLabel, SelectNamespace as Select };
